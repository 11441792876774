<template>
    <b-container fluid>
        <b-row>
            <Breadcrumb titulo="Configuração Gpt" :items="item" backgroundColor="#666" />
        </b-row>
        <b-row style="padding: 10px 15px 0px 0px">
            <b-col>
                <div class="row" id="linha1">
                    <div class="col" style="margin-left:15px" id="colunaTabs">
                        <div class="h-100">
                            <b-row id="abasInformacao">
                                <div class="px-4 py-2" v-for="aba in abas" :data-id="aba.id" @click="currentAba = aba.id" :class="currentAba == aba.id ? 'active' : ''">
                                    {{aba.titulo}}
                                </div>
                            </b-row>
                            <b-row id="abasConteudo" class="px-2 py-2 " style="height: calc(90vh - 150px)">
                                <b-col class="h-100">
                                    <b-container v-for="aba in abas" v-if="currentAba == aba.id" fluid class="py-3 h-100">
                                        <div v-if="aba.id == 1" class="h-100 ">
                                            <b-col class="p-0" style="margin:5px;  background-color: #e8e8e8">
                                                <b-row class="mx-0 my-0">
                                                    <b-col></b-col>
                                                    <b-col>
                                                        <div id="btnNovoPrompt" @click="abrirModalCriar">Novo prompt</div>
                                                    </b-col>
                                                </b-row>
                                                <b-row class="mx-0 my-0">
                                                    <b-col>
                                                        <div id="caixaTabelaPrompt">
                                                            <b-table striped hover :items="promptCadastrados" :fields="fieldsPrompt" style="font-size:12px">
                                                                <template #cell(DataCadastro)="data">
                                                                    {{data.item.DataCadastro}}
                                                                    <i class="fas fa-trash-alt float-right ml-2 P-icons" @click='excluirPrompt(data)'></i>
                                                                    <i class="fas fa-pen float-right ml-2 P-icons" @click='editarPrompt(data)'></i>
                                                                </template>
                                                            </b-table>
                                                        </div>

                                                    </b-col>

                                                </b-row>
                                            </b-col>
                                        </div>
                                        <div v-if="aba.id == 2" class="h-100 app-scroll-custom">
                                            <b-table striped hover :items="listaSetores" :fields="fieldsSetores" style="font-size:12px">
                                                <template #cell(Prompts)="data">
                                                    <select @change="atualizarSetorPrompt($event, data)" v-model="data.item.GPTConfiguracaoId">
                                                        <option :value="null">Desligado</option>
                                                        <option :value="item.Id" v-for="item in promptCadastrados">{{item.Titulo}}</option>
                                                    </select>
                                                </template>
                                            </b-table>
                                        </div>
                                    </b-container>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
        <ModalCriarPrompt ref="modalCriarPrompt" @carregaNovoPrompt="carregaNovoPrompt" @carregaEdicaoPrompt="carregaEdicaoPrompt" :dadosPrompt="dadosPrompt" />
    </b-container>
</template>

<script>
    import axios from 'axios';
    import Breadcrumb from '@/components/breadcrumb/Breadcrumb.vue'
    import ModalCriarPrompt from '@/components/chatGpt/ModalCriarPrompt.vue';

    class Setor {
        constructor(setor) {
            this.Id = setor.Id;
            this.Nome = setor.Nome;
            this.GPTConfiguracaoId = setor.GPTConfiguracaoId ?? null;
        }
    }

    export default {
        name: 'GptPage',

        components: {
            Breadcrumb,
            ModalCriarPrompt
        },

        data: function () {
            return {
                item: [],
                promptCadastrados: null,
                fieldsPrompt: [{ key: 'Titulo', label: "Titulo" }, { key: 'DataCadastro', label: "Data criação" }],
                fieldsSetores: [{ key: 'Nome', label: "Nome" }, { key: 'Prompts', label: "Prompts" }],
                dadosPrompt: null,
                currentAba: 1,
                abas: [{ id: 1, titulo: "Criação" }, { id: 2, titulo: "Setores" }],
                listaSetores: null,
                setorSelecionado: null
            }
        },
        watch: {
            currentAba(abaId) {
                if (abaId == 2) {
                    this.carregarDadosSetor();
                }
            }
        },
        created() {
            this.carregaPrompts();
        },

        methods: {
            carregarDadosSetor() {
                $("#caixa_carregamento").show();
                axios.get('/api/setor/lista-basica')
                    .then((response) => {
                        console.log("Setores ", response)
                        this.listaSetores = response.data.map(item => new Setor(item));
                        $("#caixa_carregamento").hide();
                    }, (error) => {
                        console.log(error);
                        $("#caixa_carregamento").hide();
                    });
            },
            atualizarSetorPrompt(event, dados) {
                let promptId = event.target.value == 0 ? null : event.target.value;
                let data = {
                    setorId: dados.item.Id,
                    promptId: promptId
                }
                axios.put('/api/setor/AlterarSetorGPT', data)
                    .then((response) => {
                        this.promptNotificacao("prompt selecionado criado com sucesso!");
                        $("#caixa_carregamento").hide();
                    }, (error) => {
                        console.log(error);
                        $("#caixa_carregamento").hide()
                    });

            },
            abrirModalCriar() {
                this.dadosPrompt = null;
                this.$refs["modalCriarPrompt"].show();
            },
            carregaPrompts() {
                $("#caixa_carregamento").show();
                axios.get('/api/ChatGPT/BuscaPrompts')
                    .then((response) => {
                        this.promptCadastrados = response.data;
                        $("#caixa_carregamento").hide();
                    }, (error) => {
                        console.log(error);
                        $("#caixa_carregamento").hide();
                    });
            },

            salvaConfigGpt() {
                let data = {

                    prompt: this.prompt,
                    textoInformativo: this.informativo,
                    comandoFinalizar: this.comandoFinalizar,
                    comandoTransferir: this.comandoTransferir
                }
                this.item.push(data)

            },
            carregaNovoPrompt(dadosNovoPrompt) {
                this.promptCadastrados.unshift(dadosNovoPrompt);
                this.promptNotificacao("Novo Prompt criado com sucesso!");
            },
            carregaEdicaoPrompt(dadosNovoPrompt) {
                let indexAntigo = this.promptCadastrados.findIndex(f => f.Id == dadosNovoPrompt.Id);

                this.promptCadastrados[indexAntigo].Titulo = dadosNovoPrompt.Titulo;
                this.promptCadastrados[indexAntigo].DataCadastro = dadosNovoPrompt.DataCadastro;
                this.promptNotificacao("Edição realizada com sucesso!");
            },
            promptNotificacao(mensagem) {
                this.$bvToast.toast(mensagem, {
                    title: `Aviso`,
                    toaster: 'b-toaster-top-right',
                    solid: true,
                    variant: 'success',
                    appendToast: false,
                    autoHideDelay: 3000,
                    noCloseButton: true
                });
            },
            editarPrompt(data) {
                this.dadosPrompt = data.item;
                this.$refs["modalCriarPrompt"].show().then(dados => {
                    Object.getOwnPropertyNames(data.item).forEach(key => data.item[key] = dados[key]);
                });
            },
            excluirPrompt(data) {
                axios.put('/api/ChatGPT/ExcluirPrompts/' + data.item.Id)
                    .then((response) => {
                        let indexExclusao = this.promptCadastrados.findIndex(f => f.Id == data.item.Id);
                        this.promptCadastrados.splice(indexExclusao, 1);
                        this.promptNotificacao("Prompt excluido com sucesso!");

                        $("#caixa_carregamento").hide();
                    }, (error) => {
                        console.log(error);
                        $("#caixa_carregamento").hide();
                    });
            }

        }


    }
</script>

<style scoped>

    #btnNovoPrompt {
        /*   display: inline-block;*/
        background-color: rgb(25, 177, 189);
        width: 130px !important;
        border: 6px solid rgb(25, 177, 189);
        color: #FFF;
        text-align: center;
        cursor: pointer;
        margin-top: 15px;
        float: right;
        font-size: 12px;
    }

    .P-icons {
        padding: 0px 5px;
        cursor: pointer;
    }

    #abasInformacao > div {
        background-color: #f2f2f2;
        cursor: pointer;
        color: #7d7d7d;
        border-right: 1px #e8e8e8 solid;
        margin-right: 5px;
    }

        #abasInformacao > div.active {
            background-color: #e8e8e8;
            cursor: default;
            font-weight: 600;
        }

    #abasConteudo {
        background-color: #e8e8e8;
        cursor: default;
    }
</style>