<template>
    <div class="tabela-canais mb-1">
        <b-container fluid class="px-0">

            <b-table :items="lista"
                     :fields="colunas"
                     :sort-by.sync="sortBy"
                     :sort-desc.sync="sortDesc"
                     fixed
                     hover
                     striped 
                     class="lista-dados">

                <template #cell(Pergunta)="data">
                    <div class="d-inline-block w-100 text-left">
                        <span class="mr-2" v-if="data.item.Pergunta" >{{'Pergunta: ' + data.item.Pergunta}} </span>

                        <botao-tab-cinza @click="$emit('escolherPergunta', { canalId: data.item.Id, perguntaId: data.item.PerguntaId, Tipo: data.item.Tipo })"
                                         :texto="data.item.Pergunta ? 'Alterar' : 'Selecionar' " />
                    </div>
                </template>


                <template #cell(Setor)="data">
                    <div class="d-inline-block w-100 text-left">
                        <span class="mr-2" v-if="data.item.Setor"> {{data.item.Setor}} </span>

                        <botao-tab-cinza @click="$emit('escolherSetor', { canalId: data.item.Id, setorId: data.item.SetorId })"
                                         :texto="data.item.Setor ? 'Alterar' : 'Selecionar' " />
                    </div>
                </template>

                <template #cell(Horario)="data">
                    <div class="d-inline-block w-100 text-left">
                        <b-form-checkbox :id="data.item.Id"
                                         v-on:change="selecionarForaHorario(data.item.Id)"
                                         v-model="data.item.Horario">
                        </b-form-checkbox>
                    </div>
                </template> 
                <template #cell(botoes)="row">
                    <div class="text-left">
                        <botao-tab-edit @click="$emit('editar', row.item.Id)" />
                        <botao-tab-remove @click="$emit('remover', row.item.Id)" />
                    </div>
                </template>

            </b-table>

        </b-container>
    </div>
</template>

<script>
    import axios from 'axios'

    import BotaoTabEdit from '@/components/botoes/BotaoTabEdit.vue'
    import BotaoTabRemove from '@/components/botoes/BotaoTabRemove.vue'
    import BotaoTabCinza from '@/components/botoes/BotaoTabCinza.vue'

    export default {
        name: 'TabelaCanais',
        components: {
            BotaoTabEdit,
            BotaoTabRemove,
            BotaoTabCinza
        },
        props: {
            colunas: {
                type: Array,
                default: () => []
            },
            lista: {
                type: Array,
                default: () => []
            },
        },
        data() {
            return {
                sortBy: this.colunas[0].key,
                sortDesc: false,
               
            }
        },
        methods: {
            selecionarForaHorario: function (id) {
                $("#caixa_carregamento").show();

                const acao = $("#" + id).is(":checked") == false ? "remover" : "adicionar";
                $("#caixa_carregamento").show();
                axios.post('/api/canal/setarHorario/' + id + "/" + acao)
                    .then((response) => {
                        $("#caixa_carregamento").hide()
                    }, (error) => {
                        console.log(error);
                        $("#caixa_carregamento").hide()
                    });




                console.log("canal ", id)
            }
        },
    }
</script>

<style scoped>
    .lista-dados {
        font-size: 0.8rem;
        margin: 0;
    }

    .card-setores {
        height: 100%;
    }

    .contador-itens {
        width: 100%;
        font-size: 16px;
        font-weight: 700;
    }

    .tabela-canais {
        background-color: #fff;
        overflow-y: auto;
        height: calc(100vh - 218px);
    }
</style>