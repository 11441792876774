<template>
    <div>
        <Breadcrumb titulo="CANAL" :items="itemsBreadcrumb"/>

        <div class="canais-page-container-externo-tabela">
            <div class="canais-page-container-interno-tabela">
                <header-funcoes-lista textoBotao="Novo Canal" @click="setCanal" @pesquisar="pesquisarCanal" />

                <tabela-canais :colunas="colunasCanal"
                               :lista="canais"
                               @editar="setCanal"
                               @remover="removerCanal"
                               @escolherSetor="formEscolherSetor" />

                <strong style="color: var(--cinza-5)">{{canais.length}} canais</strong>
            </div>
        </div>

        <canal-form ref="formularioCanal" :tiposCanais="listaTiposCanaisHabilitados" />
        <input-select-popup ref="selectPopup" @dadoSelecionado="salvarDadoSelecionado" />

    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import Breadcrumb from '@/components/breadcrumb/Breadcrumb.vue'
    import HeaderFuncoesLista from '@/components/tabelas/HeaderFuncoesLista.vue'
    import TabelaCanais from '@/components/canais/TabelaCanais.vue'
    import CanalForm from '@/components/canais/CanalFormPopup.vue'
    import InputSelectPopup from '@/components/canais/InputSelectPopup.vue'

    export default {
        name: 'CanaisPage',

        components: {
            Breadcrumb,
            HeaderFuncoesLista,
            TabelaCanais,
            CanalForm,
            InputSelectPopup
        },

        data: function () {
            return {
                itemsBreadcrumb: [
                    {
                        id: '2',
                        classe: 'fa fa-cogs',
                        texto: 'Cadastros Básicos',
                        link: '/cadastros-basicos'
                    },

                    {
                        id: '3',

                        classe: 'fa fa-globe',
                        texto: 'Canais',
                        link: '/canais'
                    }
                ],
                colunasCanal: [
                    { key: 'Nome', sortable: true },
                    { key: 'Tipo', sortable: true },
                    { key: 'Setor', sortable: true },
                    { key: 'Horario', sortable: false },
                   
                    { key: 'botoes', label: '' },
                ],
                textoPesquisa: '',
                dadosCanal:''
            }
        },
        watch: {
            listaTiposCanaisHabilitados() {
                this.dadosCanal = this.listaTiposCanaisHabilitados;
            }
        },
        computed: {
            ...mapGetters({
                listaCanais: 'canais/listaCanais',
                listaTiposCanais: 'canais/listaTiposCanais',
                listaTiposCanaisHabilitados: 'canais/TiposCanaisHabilitados',
                listaSetores: 'setores/listaSetores'
            }),
            canais: function () {
                if (this.listaCanais) {
                    let listagemCanais = this.listaCanais.map(obj => ({
                        Id: obj.Id,
                        Nome: obj.Nome,
                        Tipo: (obj.Tipo || obj.Tipo === 0) ? this.identificarCanalTipo(obj.Tipo) : '',
                        Setor: obj.Setor ? obj.Setor.Nome : '',
                        SetorId: obj.Setor ? obj.Setor.Id : '',
                        Alteracao: obj.AlteracaoFormatada,
                        Inclusao: obj.InclusaoFormatada,
                        Horario:obj.ForaDoHorario
                    }));

                    

                    if (this.textoPesquisa) {
                        return listagemCanais.filter(obj =>
                            obj.Nome ? obj.Nome.toLowerCase().includes(this.textoPesquisa): '' ||
                            obj.Tipo ? obj.Tipo.includes(this.textoPesquisa): '' ||
                            obj.Setor ? obj.Setor.toLowerCase().includes(this.textoPesquisa): ''
                        )
                    }
                    return listagemCanais;
                }
                return [];
            },
        },
        
        created() {
            this.buscarCanais();
            this.buscarTiposCanaisHabilitados();
            this.buscarSetores();
        },

        methods: {
            ...mapActions({
                buscarListaCanais: 'canais/buscaCanais',
                buscarTiposCanaisHabilitados: 'canais/buscaTiposCanaisHabilitados',
                buscarSetores: 'setores/buscaSetores',
            }),
            buscarCanais: function () {
                if (!this.listaCanais) {
                    this.buscarListaCanais();
                }
            },
            setCanal: function (canalId) {
                this.$refs.formularioCanal.limparFormulario();
                if (canalId) {
                    let canalSelecionado = this.listaCanais.find(x => x.Id === canalId);
                    this.$refs.formularioCanal.editarCanal(canalSelecionado);
                }
                this.$refs.formularioCanal.showModal();
            },
            removerCanal: function (canalId) {
                const mensagem = 'Deseja mesmo excluir esse canal?'
                this.$bvModal.msgBoxConfirm(mensagem, {
                    title: 'Exclusão',
                    okTitle: 'Confirmar',
                    cancelTitle: 'Cancelar',
                    centered: true
                }).then(confirmado => {
                    if (confirmado) {
                        this.$store.dispatch(`canais/remover`, canalId)
                    }
                })
            },
            pesquisarCanal: function (texto) {
                this.textoPesquisa = texto.toLowerCase();
            },
            formEscolherSetor: function ($event) {
                let opcoesSetores = this.listaSetores.map(prop => ({
                    text: prop.Nome,
                    value: prop.Id,
                }));

                const labelTexto = 'Selecione um setor'
                this.$refs.selectPopup.setDadosSelect({
                    'infoSelect': { tipoSelect: 'setores', canalId: $event.canalId },
                    'label': labelTexto,
                    'opcaoSelecionada': $event.setorId,
                    'opcoes': opcoesSetores
                });
                this.$refs.selectPopup.abrir();
            },
            removeTagsHtml: function (textoHtml) {
                let doc = new DOMParser().parseFromString(textoHtml, 'text/html');
                return doc.body.textContent || "";
            },
            salvarDadoSelecionado: function ($event) {
                if ($event.tipoSelect === 'setores') {
                    this.$store.dispatch('canais/putCanalSetor', { 'id': $event.canalId, 'setorId': $event.selected })
                }
            },
            identificarCanalTipo: function (valorTipo) {
                if (this.listaTiposCanaisHabilitados) {
                    return this.listaTiposCanaisHabilitados.find(x => x.Value === Number(valorTipo))?.Text
                }
                
                return ''
            }
        },
    }
</script>

<style scoped>
.canais-page-container-externo-tabela {
    padding: 10px;
    height: calc(100vh - 112px);
}

.canais-page-container-interno-tabela {
    padding: 10px;
    height: 100%;
    background-color: var(--cinza-3);
}
</style>